import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import DownArrowIcon from '../../assets/down_arrow.svg'

const ItemHeaderLabel = styled.div`
	flex: 1;
	text-align: center;
	font-family: 'Montserrat', sans-serif;
	font-size: 12px;
	color: ${props => (props.active ? `#ffffff` : `#b2b5be`)};
`

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-content: flex-start;
	justify-content: space-evenly;
	align-items: stretch;
`

const ItemHeaderWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-bottom: ${props => (props.active ? `2px solid #AA33B5` : ``)};
	padding: 8px 10px;
`

const MoreImg = styled.img`
	padding: 8px 0 0 4px;
`

export const ItemHeaderContainer = ({ text, active, more, onClick }) => {
	return (
		<ItemHeaderWrapper active={active} onClick={onClick}>
			<ItemHeaderLabel active={active}>{text}</ItemHeaderLabel>
			{more ? <MoreImg src={DownArrowIcon} alt='arrow-down' /> : null}
		</ItemHeaderWrapper>
	)
}

ItemHeaderContainer.propTypes = {
	active: PropTypes.bool,
	text: PropTypes.string.isRequired,
	more: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
}
ItemHeaderContainer.defaultProps = {
	more: false,
	onClick: () => {},
}
