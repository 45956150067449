import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Overlay = styled.div`
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1;
`

const Container = styled.div`
	position: fixed;
	align-self: center;
	background-color: #262654;
	border-radius: 8px;
`

const MobilePopup = ({ isOpen, close, children }) => {
	if (!isOpen) return null

	return (
		<Overlay onClick={close}>
			<Container>{children}</Container>
		</Overlay>
	)
}

MobilePopup.propTypes = {
	isOpen: PropTypes.bool,
	close: PropTypes.func,
	children: PropTypes.element,
}

export default MobilePopup
