export const webOptions = {
	disabled_features: [
		'header_fullscreen_button',
		'header_symbol_search',
		'header_compare',
		'study_templates',
		'save_shortcut',
		'save_chart_properties_to_local_storage',
		'header_saveload',
		'symbol_info',
		'symbol_search_hot_key',
		'popup_hints',
		'volume_force_overlay',
		'support_multicharts',
		'header_layouttoggle',
		'open_account_manager',
		'right_toolbar',
		'trading_account_manager',
		'show_object_tree',
		'timeframes_toolbar',
		'header_settings',
		'order_panel',
	],
	enabled_features: ['items_favoriting'],
	fullscreen: true,
	autosize: true,
	theme: 'Dark',
	custom_css_url: 'chart.css',
	loading_screen: {
		backgroundColor: '#14152c',
		foregroundColor: '#3d3f70',
	},
	logo: {
		image: '/klever-logo.svg',
		link: 'https://klever.io',
	},
	overrides: {
		'paneProperties.vertGridProperties.color': 'rgba(61, 63, 112, 0.07)',
		'paneProperties.horzGridProperties.color': 'rgba(61, 63, 112, 0.07)',
		'paneProperties.backgroundType': 'solid',
		'paneProperties.background': '#14152c',
		'mainSeriesProperties.lineStyle.color': '#aa33b5',
		'mainSeriesProperties.areaStyle.color1': 'rgba(170,51,181, 0.28)',
		'mainSeriesProperties.areaStyle.color2': '#aa33b5',
		'mainSeriesProperties.areaStyle.linecolor': '#aa33b5',
	},
	timeframe: '10D',
}

export const webDataFeedConfig = {
	supported_resolutions: ['1', '5', '15', '30', '60', '120', '240', '480', '1D', '7D', '30D'],
}
