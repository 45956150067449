import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledButton = styled.button`
	background: none;
	border: none;
	margin: 0;
	padding: 0;
	cursor: pointer;
	color: ${props => (props.isSelected ? '#FFF' : '#DDD')};
	font-weight: ${props => (props.isSelected ? 'bold' : 'normal')};
	font-size: 12px;
	font-family: 'Montserrat', sans-serif;
	padding: 8px;
	margin: 8px;
`

const Button = ({ label, isSelected, onClick }) => {
	return (
		<StyledButton onClick={onClick} isSelected={isSelected}>
			{label}
		</StyledButton>
	)
}
Button.propTypes = {
	label: PropTypes.string.isRequired,
	isSelected: PropTypes.bool,
	onClick: PropTypes.func,
}
Button.defaultProps = {
	label: 'MFA',
	isSelected: false,
	onClick: () => {},
}

export default Button
